import '@webcomponents/custom-elements'
import wixCustomElementsRegistry from '@wix/wix-custom-elements'
import imageClientApi from 'image-client-api/dist/imageClientApi'
import fastdom from 'fastdom'
import ResizeObserver from '@wix/wix-resize-observer-polyfill'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils.js'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery'
import {prefersReducedMotion} from './functionLibrary/accessability'
import mediaResizeMap from '@wix/santa-animations/dist/mediaResizeMap.js'

const queryUtil = getQueryUtils(window)
const runningExperiments = addExperimentsFromQuery(window.rendererModel.runningExperiments, queryUtil, 'viewer')

const isExperimentOpen = experimentName => {
    const val = runningExperiments[experimentName]
    return !!(val && val !== 'old' && val !== 'false')
}

const getDevicePixelRatio = () => {
    const isMSMobileDevice = /iemobile/i.test(navigator.userAgent)
    if (isMSMobileDevice) {
        return Math.round(window.screen.availWidth / (window.screen.width || window.document.documentElement.clientWidth))
    }
    return window.devicePixelRatio
}

const getMediaDimensionsByEffect = (bgEffectName, width, height, screenHeight) => {
    const {getMediaDimensions, ...rest} = mediaResizeMap[bgEffectName] || {}
    return getMediaDimensions ? {...getMediaDimensions(width, height, screenHeight), ...rest} : {width, height, ...rest}
}

const serviceTopology = window.serviceTopology

const environmentConsts = {
    staticMediaUrl: serviceTopology.staticMediaUrl,
    mediaRootUrl: serviceTopology.mediaRootUrl,
    experiments: {},
    isViewerMode: !window.rendererModel.previewMode,
    devicePixelRatio: getDevicePixelRatio()
}

const resizeService = {
    init: callback => new ResizeObserver(callback)
}

const windowResizeService = {
    init: callback => window.addEventListener('resize', callback)
}

const servicesBuffer = {
    bi: [],
    tpaBi: []
}

const biService = {
    initialized: false,
    biData: {},
    reportBI(reportDef, params) {
        servicesBuffer.bi.push({reportDef, params})
    },
    reportTpaBiEvent({reportDef, params, compId, isWixTPA, widgetId, appDefinitionId}) {
        // same logic as in reportPerformanceBiEvent function
        if (this.initialized) {
            const time = this.biData.getTime()
            const eventParams = {
                compId,
                pageId: this.biData.getPageId(),
                pageNo: this.biData.getPageNumber(),
                ssr: false,
                loadingTime: time.loadingTime,
                totalLoadingTime: time.totalLoadingTime
            }

            if (isWixTPA) {
                eventParams.widgetId = widgetId
                eventParams.appDefinitionId = appDefinitionId
            } else if (!(params && params.widgetId && params.appDefinitionId)) {
                eventParams.externalWidgetId = widgetId
                eventParams.externalAppDefinitionId = appDefinitionId
            }

            const biParams = {...eventParams, ...params}
            this.reportBI(reportDef, biParams)
        } else {
            servicesBuffer.tpaBi.push({reportDef, params, compId, isWixTPA, widgetId, appDefinitionId})
        }
    }
}

const services = {
    mutationService: fastdom,
    biService,
    isExperimentOpen
}
const mediaServices = {getMediaDimensionsByEffect, ...services}

const setServices = {
    bi: (biData, reportBIFn) => {
        if (!biData || !reportBIFn) {
            return
        }

        biService.biData = biData
        biService.reportBI = reportBIFn
        biService.initialized = true

        servicesBuffer.bi.forEach(bi => biService.reportBI(bi.reportDef, bi.params))
        servicesBuffer.bi = []
        servicesBuffer.tpaBi.forEach(bi => biService.reportTpaBiEvent(bi))
        servicesBuffer.tpaBi = []
    }
}

const wixCustomElements = wixCustomElementsRegistry.init({resizeService, windowResizeService})

wixCustomElements.defineWixDropdownMenu(services)
wixCustomElements.defineWixBgImage(mediaServices, environmentConsts)
wixCustomElements.defineWixBgMedia(mediaServices, environmentConsts)

wixCustomElements.defineWixImage(mediaServices, environmentConsts)


const reducedMotion = prefersReducedMotion() && isExperimentOpen('bv_reducedMotion')
const videoEnvironmentConsts = {
    prefersReducedMotion: reducedMotion,
    staticVideoUrl: serviceTopology.staticVideoUrl

}
wixCustomElements.defineWixVideo(services, videoEnvironmentConsts)

module.exports = {
    defineWixIframe: wixCustomElements.defineWixIframe.bind(null, services),
    setServices,
    loadedModules: {
        imageClientApi
    }
}
